<template>
  <div class="mt-3  px-2">
    <v-row type="flex" align="center" justify="center" style="width:400px">
      <v-col :cols="12">
        <v-text-field
            dense
            v-model="job.Title"
            hide-details
            outlined
            label="عنوان"
        />
      </v-col>
      <v-col :cols="12">
        <v-select
            dense
            v-model="job.jobcode"
            hide-details
            outlined
            label="شغل مورد نظر"
            :items="jobTypes"
            item-value="id"
            item-text="name"
        />
      </v-col>
      <v-col :cols="12">
        <v-select
            dense
            v-model="job.shiftwork"
            hide-details
            outlined
            label="شیفت کاری"
            :items="shiftItems"
            item-value="id"
            item-text="name"
        />
      </v-col>
      <v-col :cols="12" class="d-flex align-center justify-start">
        <v-text-field
            prepend-inner-icon="mdi-clock-time-three-outline"
            dense
            v-model="job.startinghours"
            hide-details
            outlined
            label="ساعت شروع"
            class="start-time-picker"
        />
        <date-picker
            type="time"
            :max="job.endtime"
            auto-submit
            v-model="job.startinghours"
            custom-input=".start-time-picker"
        />
        <div class="font-weight-bold text-body-2 black--text mx-2">
          تا
        </div>
        <v-text-field
            prepend-inner-icon="mdi-clock-time-three-outline"
            dense
            v-model="job.endtime"
            hide-details
            outlined
            label="ساعت پایانی"
            class="end-time-picker"
        />
        <date-picker
            type="time"
            :min="job.startinghours"
            auto-submit
            v-model="job.endtime"
            custom-input=".end-time-picker"
        />
      </v-col>
      <v-col :cols="12">
        <v-combobox
            dense
            v-model="job.locationsid"
            hide-details
            outlined
            label="منطقه کاری"
            :items="locations"
            item-value="id"
            item-text="name"
        />
      </v-col>
      <v-col :cols="12">
        <v-text-field
            dense
            v-model="job.expertise"
            hide-details
            outlined
            label="تخصص مورد نظر"
        />
      </v-col>

      <v-col :cols="12">
        <v-btn
            :disabled="!checkCanSubmit"
            @click="submit"
            color="main" class="white--text">
          تایید و رفتن به مرحله بعد
          <v-icon>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {AppConfig} from "@Newfiling/Services";
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

export default {
  name: "Step1",

  data() {
    return {
      jobTypes: [],
      locations: [],
      shiftItems: [
        {
          id: 1,
          name: 'صبح تا ظهر',
        },
        {
          id: 2,
          name: 'عصر تا شب',
        },
        {
          id: 3,
          name: 'تمام',
        },
      ],
      job: {
        Title: null,
        jobcode: null,
        shiftwork: null,
        startinghours: null,
        endtime: null,
        locationsid: null,
        expertise: null,
      }
    }
  },

  components:{
    datePicker: VuePersianDatetimePicker
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfigs = (await AppConfig) || {};
      this.jobTypes = appConfigs?.joblist.map(item => {
        return {
          id: item.Id,
          name: item.Jobname,
        }
      })
      this.locations = appConfigs?.neighborhoods.map(item => {
        return {
          id: item.Id,
          name: item.NeighborhoodName,
        }
      })
    })
  },

  computed: {
    checkCanSubmit() {
      return Boolean(
          this.job.Title &&
          this.job.jobcode &&
          this.job.shiftwork &&
          this.job.startinghours &&
          this.job.endtime &&
          this.job.locationsid &&
          this.job.expertise
      );
    }
  },

  methods: {
    submit() {
      this.$emit('nextStep', this.job)
    }
  }
}
</script>

<style scoped>

</style>