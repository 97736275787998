<template>
  <div class="job-create-desktop">
    <v-card
        :disabled="job.loading"
        :loading="job.loading"
        class="job-create-desktop__form">
      <v-card-title>
        <PageTitle
            class="w-100"
            icon="mdi-plus-box"
            title="ثبت آگهی کاریابی"/>
      </v-card-title>

      <v-card-text>

        <v-stepper
            vertical
            flat
            v-model="step">

          <v-stepper-step
              id="step-1"
              editable
              :complete="step > 1"
              step="1"
          >
            اطلاعات آگهی
          </v-stepper-step>
          <v-stepper-content
              step="1">
            <Step1
                @nextStep="(data)=>{
              gotoNextStep('step-2',data)
            }"
            />
          </v-stepper-content>


          <v-stepper-step
              id="step-2"
              :editable="step > 2"
              :complete="step > 2"
              step="2"
          >
            تصاویر و توضیحات آگهی
          </v-stepper-step>
          <v-stepper-content
              step="2">
            <Step2
                @nextStep="(data)=>{
              gotoNextStep('step-3',data)
            }"
            />
          </v-stepper-content>

        </v-stepper>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle"
import Step1 from "./Step1";
import Step2 from "./Step2";
import {store} from "@Newfiling/module-job/src/api";

export default {
  name: 'DesktopJobCreate',

  components: {
    PageTitle,
    Step1,
    Step2,
  },

  data() {
    return {
      step:1,
      job: {
        loading: false,
        Title:null,
        jobcode:null,
        shiftwork:null,
        startinghours:null,
        endtime:null,
        locationsid:null,
        expertise:null,
        description:null,
        image1:null,
        image2:null,
        image3:null,
        image4:null,
      }
    }
  },

  methods: {
    gotoNextStep(step, data = {}) {
      this.setData(data);
      if (step === 'step-3') {
        this.submitJob();
      } else {
        this.step++;
        this.$vuetify.goTo('#' + step);
      }
    },

    setData(data) {
      this.job = {
        ...this.job,
        ...data
      }

    },

    async submitJob() {
      this.job.loading = true;
      try {
        await store({
          ...this.job,
          locationsid:this.job.locationsid?.id,
        });
        this.$toast.success('آگهی شما با موفقیت ثبت شد.');
        await this.$router.push('/panel/jobs/list');
      } catch (e) {
        console.log(e)
      }
      this.job.loading = false;
    }
  }

}
</script>

<style scoped>
</style>
