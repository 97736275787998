import {httpProvider} from "@Newfiling/Services";

export const uploadImage = (file, watermark) => {
    const formData = new FormData();
    formData.append('file', file);

    return httpProvider.post('eUploadimagefile', formData);
};

export const getPermissions = () => {
    return httpProvider.post('/Permissioninformation', {});
}