<template>
  <div>
    <div class="font-weight-bold text-body-2 mb-4" v-if="label">
      {{ label }}
    </div>

    <div
        :style="{
          width: width,
          height: height,
      }"

        class="uploader">
      <div v-if="loading" class="h-100 d-flex align-center justify-center w-100">
        <v-progress-circular
            :size="30"
            color="primary"
            indeterminate
        />
      </div>
      <template v-else>
        <v-btn
            absolute
            v-if="file.url && !uploadOnly"
            @click="remove"
            color="error"
            elevation="0"
            style="z-index: 4"
            x-small
            fab>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>

        <input
            accept="image/*"
            @change="upload($event.target.files)"
            type="file">
        <div
            v-if="!file.url || uploadOnly"
            class="uploader__preview">
          <v-icon>
            mdi-plus
          </v-icon>
          <span>
                انتخاب فایل
            </span>


        </div>

        <div v-if="!uploadOnly" class="w-100 h-100 d-flex align-center justify-center">
          <img v-if="file.url"
               :src="file.url">
        </div>
      </template>

    </div>
  </div>


</template>

<script>

import {uploadImage} from "@Newfiling/api";

export default {
  name: "Uploader",

  props: {

    width: {
      type: String,
      default: '150px'
    },

    height: {
      type: String,
      default: '150px'
    },
    value: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },
    uploadOnly: {
      type: Boolean,
      default: false,
    }

  },

  mounted() {
    this.initValue();
  },

  watch: {
    value() {
      this.initValue();
    },

  },

  data() {
    return {
      file: {
        url: "",
        url2: ""
      },
      loading: false,
    }
  },

  methods: {
    async upload(files) {
      const [file] = files;
      this.loading = true;
      try {
        const res = (await uploadImage(file, 'eskan'))?.data || {};

        this.file = {
          ...this.file,
          url: res.link1,
          url2: res.link2,
        };

        this.$emit('input', this.file)
        this.$emit('change', this.file)
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },

    remove() {
      this.file = {
        ...this.file,
        url: "",
        url2: ""
      };
      this.$emit('input', this.file);
      this.$emit('change', this.file);
    },

    initValue() {
      if (!this.value) return;

      this.file.url = this.value;
      this.file.url2 = this.value;
    }
  }
}
</script>

<style scoped>
.uploader {
  border: 2px dashed #ccc;
  background-color: #eee;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.theme--dark .uploader {
  border: 2px dashed var(--v-main-base);
  background-color: #000;
}


.uploader img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.uploader input[type=file] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}

.uploader__preview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
</style>